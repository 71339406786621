<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿POR QUÉ PRESTAMOS A UNA MEDIA DEL 30% SI LA COMPETENCIA PRESTA A UNA MEDIA DEL 85%?</h1>

        <p>
          Porque creemos que <span class="secondary--text">esto va de retener al cliente</span>. Nuestras mujeres de segundo préstamo
          prácticamente no impagan.
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/our_percentages.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WhyThesePercentages",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-whyThesePercentages.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-whyThesePercentages.png") + ")"
      });
    }
  }
};
</script>
